import React from "react";
import styled from "styled-components";

import { Image } from "../../globals/UIKit";
import { StyledDesc2 } from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { transition } from "../shared/CommonStyled";

import arrowBlueRight from "../imagescommon/arrow-blue.svg";
import arrowBlueRightDark from "../imagescommon/arrow-blue-dark.svg";
import video from "./images/video.svg";

const StyledCommonLinkWrapper = styled.a`
  .darkModeColor,
  .lightModeColor {
    display: inline-block;
    padding: 16px 20px;
    background-color: #222e3e;
    border-radius: 8px;
    box-shadow: ${ELEVATION.l_1};
    ${transition};
    &:hover {
      box-shadow: ${ELEVATION.l_3};
    }
  }
  .darkModeColor {
    background-color: ${COLORS.grey_98};
    &:hover {
      background-color: ${COLORS.grey_100};
    }
  }
  .lightModeColor {
    background-color: ${COLORS.white};
  }
`;

const CommonLinkContainer = ({ decs, linkUrl, internalLink, darkMode, videoIcon }) => {
  return (
    <StyledCommonLinkWrapper href={linkUrl} target="_blank" rel="noopener noreferrer">
      <div className={darkMode ? "darkModeColor" : "lightModeColor"}>
        <StyledDesc2 variant={darkMode ? "sky" : "sky_80"}>
          {videoIcon && <Image className="arrow leftIcon md" src={video} alt="Icon" />}
          {decs}
          <Image
            className="arrow rightIcon md"
            src={darkMode ? arrowBlueRightDark : arrowBlueRight}
            alt="Icon"
            mt="-3px"
            width="24"
            height="24"
          />
        </StyledDesc2>
      </div>
    </StyledCommonLinkWrapper>
  );
};

export default CommonLinkContainer;
