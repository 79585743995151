import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";

import { COLORS } from "../../../globals/designSystem";
import { StyledSubTitle1, StyledDesc1 } from "../../shared/StyledTypography";
import { textCenter, removePaddBottom } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

import tickCircle from "../../homepagenew/images/tick-circle.svg";

const StyledExistingRestWrapper = styled.div`
  padding-bottom: 100px;
  border-bottom: 1px solid ${COLORS.grey_30};
  .existingListWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    .existingList {
      padding: 0 30px;
    }
  }
  ${mq.between("md", "lg")} {
  }
  ${mq.below.md} {
    padding-bottom: 50px;
    .existingListWrapper {
      display: block;
      .existingList {
        padding: 10px 0;
      }
    }
  }
`;
const ExistingRestApi = () => {
  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledExistingRestWrapper>
          <StyledSubTitle1 className="mobileAlign" css={textCenter}>Get GraphQL over existing REST API</StyledSubTitle1>
          <div className="existingListWrapper">
            <div className="existingList">
              <StyledDesc1><img className="leftIcon" src={tickCircle} alt="Tick" />Map your REST API to GraphQL types</StyledDesc1>
            </div>
            <div className="existingList">
              <StyledDesc1><img className="leftIcon" src={tickCircle} alt="Tick" />Codegen to generate boilerplate</StyledDesc1>
            </div>
          </div>
        </StyledExistingRestWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>

  );
};

export default ExistingRestApi;
