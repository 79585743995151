import React from "react";
import styled from "styled-components";

import CommonLinkContainer from "../../homepagenew/commonlinkcontainer";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";

import { COLORS } from "../../../globals/designSystem";
import { StyledSubTitle1, StyledSubTitle2, StyledDesc3 } from "../../shared/StyledTypography";
import { textCenter, removePaddBottom, linkCardHoverStyles, alignSelfEnd } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

const StyledResourcesWrapper = styled.div`
  .resourceHeader {
    display: flex;
    padding-bottom: 48px;
    .marginLeftAuto {
      margin-left: auto;
    }
  }
  ${mq.below.md} {
    .resourceHeader {
      display: block;
    }
  }
`;
const StyledResourcesListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 94px;
  .resourcesList {
    ${linkCardHoverStyles}
    border-radius: 8px;
    background-color: ${COLORS.white};
    padding: 32px 24px;
    display: grid;
    .alignSelfEnd {
      align-self: flex-end;
    }
  }
  ${mq.between("md", "lg")} {
    grid-gap: 16px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
`;
const Resources = ({title, resourceList, centerAlign, linkContainerText, linkContainerUrl}) => {
  const resourcesList = resourceList.map((listItem, index) => (
    <a href={listItem.linkUrl} key={index} className="resourcesList">
      <StyledSubTitle2 paddingBottom="pb32">{listItem.title}</StyledSubTitle2>
      <StyledDesc3 className="alignSelfEnd">{listItem.desc}</StyledDesc3>
    </a>
  ))
  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledResourcesWrapper>
          <div className="resourceHeader">
            <StyledSubTitle1 className="alignSelfEnd" css={alignSelfEnd + " " + (centerAlign ? textCenter : null)}>{title}</StyledSubTitle1>
            {
              linkContainerText ? (
                <div className="marginLeftAuto">
                  <CommonLinkContainer
                    bgClass="commonWhiteBgColor"
                    decs={linkContainerText}
                    linkUrl={linkContainerUrl}
                  />
                </div>
              ) : null
            }
          </div>
          <StyledResourcesListWrapper>
            {resourcesList}
          </StyledResourcesListWrapper>
        </StyledResourcesWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>

  );
};

export default Resources;
