import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ExistingGraphQLIndex from "../../components/solutions/graphqlexisting/ExistingGraphQLIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/production-ready-existing-apis.png",
};

const canonicalUrl = "https://hasura.io/graphql/production-ready-existing-apis/";

const faqSchema = [
  {
    "@type": "Question",
    name: "Does Hasura support GraphQL servers written in any language?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Yes! Hasura just needs a working GraphQL endpoint and the underlying server can be written in any language or framework of your choice.",
    },
  },
  {
    "@type": "Question",
    name: "Can I join data between multiple GraphQL servers?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Hasura Remote Joins let you combine data from multiple servers and give a single response back to the client. Relationships can be configured between different endpoints and databases and responses can be joined.",
    },
  },
  {
    "@type": "Question",
    name: "What about my existing REST APIs?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "You can write custom GraphQL schema and types for your APIs and map it to the REST API endpoint and request/response body.",
    },
  },
  {
    "@type": "Question",
    name: "Will I be able to monitor requests going to my custom GraphQL server?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "With Hasura Cloud, monitoring of all requests is automatically available. Get insights into which queries to your server are being made the most and optimise them accordingly. Distributed tracing is available to debug the different paths a request takes.",
    },
  },
];

const Security = ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "FAQPage",
          mainEntity: faqSchema,
        })}
      </script>
    </Helmet>
    <Seo
      title="Turn your existing GraphQL APIs production ready | Hasura"
      description="Hasura adds production-ready features like Authorization, Caching, Monitoring, Security to your existing GraphQL API written in any language or framework."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <ExistingGraphQLIndex />
  </Layout>
);

export default Security;
