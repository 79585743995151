import React, { Fragment, useRef, useMemo } from "react";

import SubNav from "../common/SubNav";
import TopBanner from "../common/TopBanner";
import CachingWithHasura from "../common/CachingWithHasura";
import Features from "../../database/sqlServer/Features";
import ExistingRestApi from "./ExistingRestApi";
import Resources from "../common/Resources";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import GetStarted from "../common/GetStarted";
import Faq from "../common/Faq";

import { StyledDesc1 } from "../../shared/StyledTypography";

import cachingDemo from "../images/caching-demo.mp4";
import monitoringPanel from "../images/monitoring.png";
import secureExisting from "../images/secure-existing.png";
import rateLimiting from "../images/rate-limiting.png";
import responsesDataSources from "../images/responses-data-sources.svg";
import remoteSchemaArch from "../images/remote-schema-arch.svg";
import server from "../images/server.svg";
import monitor from "../images/monitor.svg";
import database from "../images/database-icon.svg";
import auth from "../images/auth.svg";

const topBannerState = {
  title: "Make your existing GraphQL APIs production ready",
  subTitle: "Add your GraphQL server(s) to Hasura instantly",
  btnContent: "Get Started with Hasura Cloud",
  btnLink: "https://cloud.hasura.io/signup?pg=production-ready-existing-apis&plcmt=body&cta=get-started-with-hasura-cloud&tech=default",
  list: ["Secure your existing API without writing any code", "Add GraphQL caching to reduce response times", "Join your API response data with any source"],
  width75: true,
}

const howDoesWorkListState = [
  "Add your GraphQL Server as a Remote Schema",
  "Forward relevant headers",
  "Define permission rules",
  "Join data between remote schema and existing schema",
]

const existingGraphQLListState = [
  {
    id: "secure-existing-graphql-api",
    imgSrc: auth,
    linkContent: "Secure your existing GraphQL API",
    title: "Secure your existing GraphQL API",
    subTitle: "Add Authorization to your GraphQL Server",
    desc: <span>
      <ul className="discUl">
        <li className="disc">No code</li>
        <li className="disc">Declaratively add permission rules</li>
        <li className="disc">Role based</li>
        <li className="disc">Field level granularity</li>
        <li className="disc">Apply token claims as filters</li>
        <li className="disc">Read more about <a href="/graphql/security/">GraphQL Security</a></li>
      </ul>
    </span>,
    panelImg: secureExisting,
    subTitle2: "Enable Rate Limiting and Query Depth Limiting",
    desc2: <span>
      <ul className="discUl">
        <li className="disc">Limit requests to your GraphQL API per min/hour</li>
        <li className="disc">Apply limits based on user identity and session variables</li>
        <li className="disc">Prevent scraping and abusing with Allow Lists</li>
      </ul>
    </span>,
    panelImg2: rateLimiting,
  },
  {
    id: "cache-existing-graphql-server",
    imgSrc: server,
    linkContent: "Cache your existing GraphQL Server",
    title: "Cache your existing GraphQL Server",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Add the `@cached` directive to existing GraphQL types</li>
        <li className="disc">Get automatic edge caching for free</li>
        <li className="disc">Cache authenticated data</li>
        <li className="disc">Learn more about <a href="/graphql/caching/">Caching with GraphQL</a></li>
      </ul>
    </span>,
    panelVideo: cachingDemo,
  },
  {
    id: "monitoring-observability",
    imgSrc: monitor,
    linkContent: "Monitoring and Observability",
    title: "Monitoring and Observability",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Monitor errors and get detailed breakdown of individual requests</li>
        <li className="disc">Distributed tracing for your API</li>
        <li className="disc">Track real time subscription connections</li>
        <li className="disc">Learn more about <a href="/graphql/monitoring/">GraphQL Monitoring and Observability</a></li>
      </ul>
    </span>,
    panelImg: monitoringPanel,
  },
  {
    id: "responses-data-sources",
    imgSrc: database,
    linkContent: "Join API response with other data sources",
    title: "Join API response with other data sources",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Establish relationships between other data sources via Remote Joins</li>
        <li className="disc">Join with database or any other GraphQL source</li>
      </ul>
    </span>,
    panelImg: responsesDataSources,
  },
]

const resourceListState =[
  {
    title: "Turn your Java REST API to GraphQL",
    desc: "Learn how to convert an existing REST API in Java Spring Boot to GraphQL.",
    linkUrl: "https://hasura.io/blog/turn-your-java-rest-api-to-graphql-using-hasura-actions/",
  },
  {
    title: "Remote Joins",
    desc: "Join data across database and other data sources using Hasura Remote Joins.",
    linkUrl: "https://hasura.io/blog/remote-joins-a-graphql-api-to-join-database-and-other-data-sources/",
  },
  {
    title: "Actions",
    desc: "Add any kind of business logic to your GraphQL API easily.",
    linkUrl: "https://hasura.io/blog/introducing-actions/",
  },
]

const faqAllState = [
  {
    id: "does-hasura-support-graphql-servers-written-in-any-language",
    question: "Does Hasura support GraphQL servers written in any language?",
    answer: <StyledDesc1>
      Yes! Hasura just needs a working GraphQL endpoint and the underlying server can be written in any language or framework of your choice.
    </StyledDesc1>,
  },
  {
    id: "can-i-join-data-between-multiple-graphql-servers",
    question: "Can I join data between multiple GraphQL servers?",
    answer: <StyledDesc1>
      Hasura Remote Joins let you combine data from multiple servers and give a single response back to the client. Relationships can be configured between different endpoints and databases and responses can be joined.
    </StyledDesc1>,
  },
  {
    id: "what-about-my-existing-rest-apis",
    question: "What about my existing REST APIs?",
    answer: <StyledDesc1>
      You can write custom GraphQL schema and types for your APIs and map it to the REST API endpoint and request/response body.
    </StyledDesc1>,
  },
  {
    id: "will-i-be-able-to-monitor-requests-going-to-my-custom-graphql-server",
    question: "Will I be able to monitor requests going to my custom GraphQL server?",
    answer: <StyledDesc1>
      With Hasura Cloud, monitoring of all requests is automatically available. Get insights into which queries to your server are being made the most and optimise them accordingly. Distributed tracing is available to debug the different paths a request takes.
    </StyledDesc1>,
  },
]

const ExistingGraphQLIndex = () => {
  const howDoesWorkRef = useRef(null);
  const featuresRef = useRef(null);
  const existingRestApiRef = useRef(null);
  const resourcesRef = useRef(null);
  const secureExistingGraphQLApiRef = useRef(null);
  const cacheExistingGraphQLServerRef = useRef(null);
  const monitoringAnalyticsRef = useRef(null);
  const responsesDataSourcesRef = useRef(null);

  const sectionRefs = useMemo(
    () => [
      { name: "How Does It Work", section: "how-does-work", ref: howDoesWorkRef },
      { name: "Features", section: "features", ref: featuresRef},
      { name: "Existing REST API", section: "existing-rest-api", ref: existingRestApiRef },
      { name: "Resources", section: "resources", ref: resourcesRef },
    ],
    []
  );

  const existingGraphQLSection = {
    "secure-existing-graphql-api": { section: "secure-existing-graphql-api", ref: secureExistingGraphQLApiRef },
    "cache-existing-graphql-server": { section: "cache-existing-graphql-server", ref: cacheExistingGraphQLServerRef },
    "monitoring-observability": { section: "monitoring-observability", ref: monitoringAnalyticsRef },
    "responses-data-sources": { section: "responses-data-sources", ref: responsesDataSourcesRef },
  }

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Existing GraphQL APIs"
        pagePath="/graphql/production-ready-existing-apis/"
        currentDropDownMenu="solutions"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=production-ready-existing-apis&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState} paddBottom borderBottom/>
      <div id="how-does-work" ref={howDoesWorkRef}>
        <CachingWithHasura
          title="How does it work?"
          list={howDoesWorkListState}
          imgsrc={remoteSchemaArch}
          pagePaddRemove
          leftPaddRemove
          topPaddRemove
        />

        {/* <ParametersSecurity /> */}
      </div>
      <div id="features" ref={featuresRef}>
        <Features
          title="What can you do with Hasura?"
          sections = {existingGraphQLSection}
          tabListState = {existingGraphQLListState}
        />
      </div>
      <div id="existing-rest-api" ref={existingRestApiRef}>
        <ExistingRestApi />
      </div>
      <div id="resources" ref={resourcesRef}>
        <Resources
          title="Resources"
          resourceList = {resourceListState}
          // linkContainerText="Check out our guide on incrementally adopting GraphQL with Hasura."
          // linkContainerUrl=""
        />
        <StyledSectionWrapper>
          <GetStarted
            btnContent="Start with Hasura Cloud"
            btnLink="https://cloud.hasura.io/signup?pg=production-ready-existing-apis&plcmt=body&cta=start-with-hasura-cloud&tech=default"
          />
        </StyledSectionWrapper>
      </div>
      <Faq
        title="Frequently Asked Questions"
        faqAllState={ faqAllState }
      />
    </>
  );
};

export default ExistingGraphQLIndex;
